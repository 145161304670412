import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { TOTPSurvey } from '@guider-global/shared-types';
import { Avatar, Header, theme } from '@guider-global/ui';
import { ThemeProvider, useTheme } from '@mui/system';
import { PageWrapper } from 'components';
import { OTPSurveyContainer } from 'containers/Surveys/OTPSurveyContainer';
import { useOTPSurveys } from 'hooks/useOTPSurveys';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';
import { deepMerge } from 'utils';
import { SurveyErrorView } from 'views';

export function OTPSurveyPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId') ?? '';
  const localeCode = searchParams.get('locale') ?? 'en_GB';
  const organizationSlug = getSubDomain();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideNavbar(true));
    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  const { organization } = useOrganization({
    organizationSlug,
    localeCode,
  });

  const { settings } = useSettings({
    localeCode,
  });

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const {
    otpSurveys,
    errorsOTPSurveys,
    isLoadingOTPSurveys,
    isErrorOTPSurveys,
  } = useOTPSurveys({
    id,
    params: { userId },
  });

  const otpSurvey = otpSurveys?.at(0) as TOTPSurvey;

  if (isLoadingOTPSurveys) {
    return <></>;
  }

  if (isErrorOTPSurveys || !otpSurvey) {
    const errorSettings =
      errorsOTPSurveys?.response?.status === 409
        ? settings?.guider_surveys?.survey_already_submitted_state
        : settings?.guider_surveys?.survey_not_found_state;

    return (
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Header
          logo={
            <Avatar
              size="large"
              hasBorder={false}
              sx={{ objectFit: 'contain !important' }}
              src={buildSanityImageUrl({
                source: organization?.white_label?.auth0_logo ?? '',
              })}
            />
          }
        />
        <PageWrapper>
          <SurveyErrorView
            title={errorSettings?.title}
            description={errorSettings?.description}
            buttonLabel={errorSettings?.return_to_the_platform_button_label}
          />
        </PageWrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <Header
        logo={
          <Avatar
            size="large"
            hasBorder={false}
            sx={{ objectFit: 'contain !important' }}
            src={buildSanityImageUrl({
              source: organization?.white_label?.auth0_logo ?? '',
            })}
          />
        }
      />
      <PageWrapper>
        <OTPSurveyContainer survey={otpSurvey} settings={settings} />
      </PageWrapper>
    </ThemeProvider>
  );
}
