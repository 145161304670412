import { Box, Button, Stack, Typography } from '@mui/material';
import { useMatches, useMatchesV2, useMemberships, useProfiles } from 'hooks';
import { useAuth } from '@guider-global/auth-hooks';
import { useParams } from 'react-router-dom';
import { getSubDomain } from '@guider-global/front-end-utils';
import { Loading } from '@guider-global/ui';

export function MatchingV2DemoPage() {
  // Handle auth
  useAuth({});

  const { programSlug } = useParams();
  const organizationSlug = getSubDomain();

  const { matchesV2, reqMatchesV2, isLoadingMatchesV2 } = useMatchesV2({});
  const { matches, reqMatches, isLoadingMatches } = useMatches({
    query: `?organizationSlug=${organizationSlug}&programSlug=${programSlug}`,
  });

  const { getProfiles } = useProfiles({});

  const { memberships } = useMemberships({});
  const membership = memberships().find(
    (membership) =>
      membership.role === 'trainee' && membership.programSlug === programSlug,
  );
  const skills = membership?.skills?.map((skill: any) => `${skill.fieldSlug} `);
  const goalCategories = membership?.goalCategories?.map(
    (goalCategory: any) => `${goalCategory.fieldSlug} `,
  );
  const programFields = membership?.programFields?.map(
    (field: any) => `${field.fieldSlug}: ${field.value} `,
  );
  const organizationFields = getProfiles()[0]?.organizationFields?.map(
    (field: any) => `${field.fieldSlug}: ${field.value} `,
  );

  const { country, townOrCity, jobTitle, firstName, lastName } =
    getProfiles()[0];

  const handleMatches = () =>
    reqMatches({
      url: `/matches?organizationSlug=${organizationSlug}&programSlug=${programSlug}&page=1&pageLimit=6`,
    });
  const handleMatchesV2 = () =>
    reqMatchesV2({
      url: `/matchesV2?resultType=guideMembership&programSlug=${programSlug}&organizationSlug=${organizationSlug}&page=1&pageLimit=6`,
    });

  return (
    <>
      <Box
        sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}
      >
        <Typography variant="h4" style={{ textWrap: 'wrap' }}>
          {`${firstName} ${lastName}`}
        </Typography>
        <br />
        <Typography>{`Skills: ${skills}`}</Typography>
        <br />
        <Typography>{`Goal Categories: ${goalCategories}`}</Typography>
        <br />
        <Typography>{`Program Fields - ${programFields}`}</Typography>
        <br />
        <Typography>{`Organization Fields - ${organizationFields}, country: ${country}, townOrCity: ${townOrCity}, jobTitle: ${jobTitle}`}</Typography>
        <br />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', py: 5, px: 4 }}
      >
        <Stack spacing={5} sx={{ width: '40%' }}>
          <Button fullWidth variant="contained" onClick={handleMatches}>
            Request Matches
          </Button>
          {!isLoadingMatches ? (
            matches?.map((match: any, index) => (
              <Box key={index}>
                <Typography variant="h6" style={{ textWrap: 'wrap' }}>
                  {`${index + 1}. ${match.membership.profile.firstName} ${
                    match.membership.profile.lastName
                  }`}
                </Typography>
                <br />
                <Typography>{`Score: ${match.score}`}</Typography>
                <br />
                <Typography>{`Skills: ${match?.membership?.skills?.map(
                  (skill: any) => `${skill.fieldSlug} `,
                )}`}</Typography>
                <br />
                <Typography>{`Program Fields - ${match?.membership?.programFields?.map(
                  (field: any) =>
                    `${field.fieldSlug}: ${JSON.stringify(field.value)}, `,
                )}`}</Typography>
                <br />
                <Typography>{`Organization Fields - ${match.membership.profile.organizationFields.map(
                  (field: any) =>
                    `${field.fieldSlug}: ${JSON.stringify(field.value)}, `,
                )}, country: ${match.membership.profile.country}, townOrCity: ${
                  match.membership.profile.townOrCity
                }, jobTitle: ${match.membership.profile.jobTitle}`}</Typography>
                <br />
              </Box>
            ))
          ) : (
            <Loading />
          )}
        </Stack>
        <Stack spacing={5} sx={{ width: '40%' }}>
          <Button fullWidth variant="contained" onClick={handleMatchesV2}>
            Request Matches V2
          </Button>
          {!isLoadingMatchesV2 ? (
            matchesV2?.map((match: any, index) => (
              <Box key={index}>
                <Typography variant="h6" style={{ textWrap: 'wrap' }}>
                  {`${index + 1}. ${match.profile.firstName} ${
                    match.profile.lastName
                  }`}
                </Typography>
                <br />
                <Typography>{`User: ${match?.user?.email} ${match?.user.id} ${match?.user?.picture}`}</Typography>
                <br />
                <Typography>{`Score: ${match.percentage}`}</Typography>
                <br />
                <Typography>{`Skills: ${match?.skills?.map(
                  (skill: any) =>
                    `${skill.fieldSlug}: ${
                      skill.matched === true ? 'matched' : 'not matched'
                    }`,
                )}`}</Typography>
                <Typography>{`Goals: ${match?.goalCategories?.map(
                  (goalCategory: any) => `${goalCategory.fieldSlug} `,
                )}`}</Typography>
                <br />
                <Typography>{`Program Fields - ${match?.membership?.programFields?.map(
                  (field: any) =>
                    `${field.fieldSlug}: ${JSON.stringify(field.value)}, `,
                )}`}</Typography>
                <br />
                <Typography>{`Organization Fields - ${match?.profile?.organizationFields?.map(
                  (field: any) =>
                    `${field.fieldSlug}: ${JSON.stringify(field.value)}, `,
                )}, country: ${match.profile.country}, townOrCity: ${
                  match.profile.townOrCity
                }, jobTitle: ${match.profile.jobTitle}`}</Typography>
                <br />
              </Box>
            ))
          ) : (
            <Loading />
          )}
        </Stack>
      </Box>
    </>
  );
}
